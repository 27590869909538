import user from './menu-list/user';
import template from './menu-list/template';
import workflow from './menu-list/workflow';
import login from './menu-list/logout';
import registerType from './menu-list/registerType';
import setting from './menu-list/setting';
import logs from './menu-list/logs';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [workflow, user, template, registerType, logs, setting, login]
};

export default menuItems;
