import seroSvg from 'assets/images/xero.svg';

const xero = {
    id: 'xero',
    type: 'group',
    children: [
        {
            id: 'xero',
            title: 'Xero',
            type: 'item',
            icon: seroSvg,
            url: '/logout',
            breadcrumbs: false
        }
    ]
};

export default xero;
