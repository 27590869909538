import React from "react";
import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@mui/material';

// project imports
import config from 'config';
import Logo from 'assets/images/logo.jpeg';

import { getVersion } from "utils/common";

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <div style={{textAlign: "center"}}>
        <ButtonBase disableRipple component={Link} to={""}>
            <img src={Logo} width={100} height={55} alt="logo" />
        </ButtonBase>
        <span style={{fontSize: "12px", fontWeight: "600", display: "block"}}>Version {getVersion()} </span>
    </div>
);

export default LogoSection;
