import instance from './axiosConfig';

export function token() {
    if (localStorage.getItem('token')) {
        let data = localStorage.getItem('token');
        let parseData = JSON.parse(data);

        return parseData.token;
    }
    return null;
}
console.log(token());

function headers() {
    return {
        'Content-Type': 'application/json',
        'x-access-token': token()
    };
}

export function addPeople(data) {
    return instance.post('/people/savepeople', data, { headers: headers() });
}

export function addCompany(data) {
    return instance.post('/company/saveclientcompany', data, { headers: headers() });
}
export function updatCompanyChanges(data) {
    return instance.post('/company/updatCompanyChanges', data, { headers: headers() });
}

export function getclientcompany(data) {
    return instance.post('/company/getclientcompany', data, { headers: headers() });
}
export function getcompanydetail(data) {
    return instance.post('/company/getcompanydetail', data, { headers: headers() });
}
export function updateclientcompany(data) {
    return instance.post('/company/updateclientcompany', data, { headers: headers() });
}
export function updatestatus(data) {
    return instance.post('/company/updatestatus', data, { headers: headers() });
}
export function getallcompany(params) {
    return instance.get('/company/getallcompany', { 
        headers: headers(),
        params: params ? params : {}
    });
}
export function getchangescompany(companyid) {
    return instance.get(`/company/getchangescompany/${companyid}`, { headers: headers() });
}

export function allPeople(params) {
    return instance.get('/people/getallpeople', {
        headers: headers(),
        params: params ? params : {}
    });
}
export function peopledetail(data) {
    return instance.post('/people/peopledetail', data, { headers: headers() });
}
export function updatePeople(data) {
    return instance.post('/people/updatepeople', data, { headers: headers() });
}
export function deletePeople(data) {
    return instance.post('/people/deletepeople', data, { headers: headers() });
}

export function login(data) {
    return instance.post('/user/login', data);
}

export function adminlogin(data) {
    return instance.post('/user/adminlogin', data);
}
export function getusers() {
    return instance.get('/people/getusers', { headers: headers() });
}

export function addStaff(data) {
    return instance.post('/admin/savestaff', data, { headers: headers() });
}
export function updateStaff(data) {
    return instance.post('/admin/updateStaff', data, { headers: headers() });
}
export function getStaff() {
    return instance.get('/admin/getstaff', { headers: headers() });
}
export function getstaffdetail(data) {
    return instance.post('/admin/getstaffdetail', data, { headers: headers() });
}
export function deleteStaff(data) {
    return instance.post('/admin/deletestaff', data, { headers: headers() });
}

export function addtemplate(data) {
    return instance.post('/admin/add_template', data, { headers: { 'Content-Type': 'multipart/form-data', 'x-access-token': token() } });
}

export function allTemplate() {
    return instance.get('/admin/alltemplate', { headers: headers() });
}
export function updateTemplate(data) {
    return instance.post('/admin/updatetemplate', data, { headers: headers() });
}
export function deleteTemplate(data) {
    return instance.post('/admin/deletetemplate', data, { headers: headers() });
}
export function getTemplatedetails(data) {
    return instance.post('/admin/gettemplatedetails', data, { headers: headers() });
}
export function getTemplateBase64(template) {
    return instance.get('/admin/gettemplateBase64', {
        headers: headers(),
        params: {
            _id: template
        }
    });
}

export function addWorkFlow(data) {
    return instance.post('/admin/add_workflow', data, { headers: headers() });
}

export function allWorkFlow() {
    return instance.get('/admin/allworkflow', { headers: headers() });
}
export function updateWorkFlow(data) {
    return instance.post('/admin/updateworkflow', data, { headers: headers() });
}
export function deleteWorkFlow(data) {
    return instance.post('/admin/deleteworkflow', data, { headers: headers() });
}
export function getWorkFlowdetails(data) {
    return instance.post('/admin/getworkflowdetails', data, { headers: headers() });
}

export function getRegisterType(id) {
    return instance.get(`/admin/registertype/${id}`, { headers: headers() });
}
export function getRegisterTypes() {
    return instance.get('/admin/registertype', { headers: headers() });
}
export function addRegisterType(data) {
    return instance.post('/admin/registertype', data, { headers: headers() });
}
export function updateRegisterType(data, id) {
    return instance.put(`/admin/registertype/${id}`, data, { headers: headers() });
}
export function deleteRegisterType(id) {
    return instance.delete(`/admin/registertype/${id}`, { headers: headers() });
}

export function getRegister(id) {
    return instance.get(`/register/${id}`, { headers: headers() });
}
export function getRegisters(id, companyId) {
    return instance.get('/register', { 
        headers: headers(),
        params: { registerTypeId: id, clientCompanyId: companyId }
    });
}
export function addRegister(data) {
    return instance.post('/register', data, { headers: headers() });
}
export function updateRegister(data, id) {
    return instance.put(`/register/${id}`, data, { headers: headers() });
}
export function deleteRegister(id) {
    return instance.delete(`/register/${id}`, { headers: headers() });
}

export function saveothercompany(data) {
    return instance.post('/othercompany/saveothercompany', data, { headers: headers() });
}
export function getothercompany(data) {
    return instance.post('/othercompany/getothercompany', data, { headers: headers() });
}
export function updateothercompany(data) {
    return instance.post('/othercompany/updateothercompany', data, { headers: headers() });
}
export function deleteothercompany(data) {
    return instance.post('/othercompany/deleteothercompany', data, { headers: headers() });
}
export function getallothercompany(params) {
    return instance.get('/othercompany/getallcompany', { 
        headers: headers(),
        params: params ? params : {}
    });
}
export function savenewtask(data) {
    return instance.post('/newtask/savenewtask', data, { headers: { 'Content-Type': 'multipart/form-data', 'x-access-token': token() } });
}
export function savedrafttask(data) {
    return instance.post('/newtask/savedraft', data, { headers: { 'Content-Type': 'multipart/form-data', 'x-access-token': token() } });
}
export function updatetask(data) {
    return instance.post('/newtask/updateTask', data, { headers: headers() });
}
export function updateChanges(data) {
    return instance.post('/newtask/updateChanges', data, { headers: headers() });
}
export function getTaskDetail(data) {
    return instance.post('/newtask/getTaskDetail', data, { headers: headers() });
}
export function saveAdditionalofficer(data) {
    return instance.post('/newtask/saveofficer', data, { headers: headers() });
}
export function getTaskDetailbyCompany(data) {
    return instance.post('/newtask/getTaskDetailbyCompany', data, { headers: headers() });
}
export function updateTaskStatus(data) {
    return instance.post('/newtask/updateTaskStatus', data, { headers: headers() });
}
export function getalltasksofCompant(data) {
    return instance.post('/newtask/getalltasksofCompant', data, { headers: headers() });
}
export function adddocument(data) {
    return instance.post('/document/adddocument', data, { headers: { 'Content-Type': 'multipart/form-data', 'x-access-token': token() } });
}
export function editdocument(data) {
    return instance.post('/document/editdocument', data, { headers: { 'Content-Type': 'multipart/form-data', 'x-access-token': token() } });
}
export function getalltasks(params) {
    return instance.get('/newtask/getalltasks', {
        headers: headers(),
        params: params ? params : {}
    });
}
export function deleteTask(data) {
    return instance.post('/newtask/deleteTask', data, { headers: headers() });
}
export function alldocument(data) {
    return instance.post('/document/alldocument', data, { headers: headers() });
}
export function getkeydocument(data) {
    return instance.post('/document/getkeydocument', data, { headers: headers() });
}
export function getkeydocumentdetail(id) {
    return instance.get(`/document/getkeydocument/${id}`, { headers: headers() });
}
export function savebilling(data) {
    return instance.post('/billing/savebilling', data, { headers: headers() });
}
export function updatebilling(data) {
    return instance.post('/billing/updateBilling', data, { headers: headers() });
}
export function BillingDetails(data) {
    return instance.post('/billing/BillingDetail', data, { headers: headers() });
}
export function getBillingCompany(data) {
    return instance.post('/billing/getBillingCompany', data, { headers: headers() });
}
export function getallbillings() {
    return instance.get('/billing/getallbillings', { headers: headers() });
}
export function getchangesofficer(companyid) {
    return instance.get(`/officer/getchangesofficer/${companyid}`, { headers: headers() });
}
export function saveofficer(data) {
    return instance.post('/officer/saveofficer', data, { headers: headers() });
}
export function updateofficer(data) {
    return instance.post('/officer/updateofficer', data, { headers: headers() });
}
export function deleteofficer(data) {
    return instance.post('/officer/deleteofficer', data, { headers: headers() });
}
export function changeofficerdetail(data) {
    return instance.post('/officer/changeofficerdetail', data, { headers: headers() });
}
export function getchangesshareholder(companyid) {
    return instance.get(`/shareholder/getchangesshareholder/${companyid}`, { headers: headers() });
}
export function saveshareholder(data) {
    return instance.post('/shareholder/saveshareholder', data, { headers: headers() });
}
export function updateshareholder(data) {
    return instance.post('/shareholder/updateshareholder', data, { headers: headers() });
}
export function getofficerdetail(data) {
    return instance.post('/officer/getofficerdetail', data, { headers: headers() });
}
export function getshareholderdetail(data) {
    return instance.post('/shareholder/getshareholderdetail', data, { headers: headers() });
}
export function deleteshareholder(data) {
    return instance.post('/shareholder/deleteshareholder', data, { headers: headers() });
}
export function getallofficer(id, officerstatus) {
    return instance.get('/officer/getallofficer', { 
        headers: headers(),
        params: { companyid: id, officerstatus: officerstatus }
    });
}
export function getallshareolder(id, filterDate) {
    return instance.get('/shareholder/getallshareolder', { 
        headers: headers(),
        params: { companyid: id, filterDate: filterDate }
    });
}
export function getcompliances() {
    return instance.get('/compliance/getcompliances', { headers: headers() });
}
export function getcompliancedetail(id) {
    return instance.get(`/compliance/getcompliancedetail/${id}`, { headers: headers() });
}
export function getcompliancedoc(link) {
    return instance.get(`/compliance/getcompliancedoc`, { 
        headers: headers(),
        params: { url: link }
    });
}
export function sendcomplianceemail(data) {
    return instance.post('/compliance/sendemail', data, { headers: headers() });
}
export function updatecompliance(data, id) {
    return instance.put(`/compliance/updatecompliance/${id}`, data, { headers: headers() });
}
export function deletecompliance(data, id) {
    return instance.put(`/compliance/deletecompliance/${id}`, data, { headers: headers() });
}
export function confirmcompliance(data) {
    return instance.post('/user/compliance/confirmsubmit', data, { headers: headers() });
}
export function savekeydocument(data) {
    return instance.post('/document/savekeydocument', data, { headers: headers() });
}
export function getdetailofficer(data) {
    return instance.post('/officer/getdetail', data, { headers: headers() });
}
export function getdetailshareholder(data) {
    return instance.post('/shareholder/getdetail', data, { headers: headers() });
}
export function savenewrequest(data) {
    return instance.post('/request/savenewrequest', data, {
        headers: { 'Content-Type': 'multipart/form-data', 'x-access-token': token() }
    });
}
export function getallrequests() {
    return instance.get('/request/getallrequests', { headers: headers() });
}
export function deleterequest(id) {
    return instance.delete(`/request/${id}`, { headers: headers() });
}

export function getPdfTemplate(id) {
    return instance.get(`/signdoc/template/${id}`, { headers: headers() });
}

export function getAdditionalDocTemplate(data) {
    return instance.post(`/signdoc/additionaldoc/`, data, { headers: headers() });
}

export function getPdfSign(type, signid) {
    return instance.get(`/signdoc/pdf/${type}/${signid}`, { headers: headers() });
}

export function getSignRequest(type, signid) {
    return instance.get(`/signdoc/type/${type}/${signid}`, { headers: headers() });
}
export function getSignRequests(query) {
    return instance.get(`/signdoc/list/doc`, { 
        headers: headers(),
        params: query
    });
}
export function sendReminder(data) {
    return instance.post('/signdoc/sendreminder', data, { headers: headers() });
}
export function cancelRequest(data) {
    return instance.post('/signdoc/cancel', data, { headers: headers() });
}
export function updatepassword(data) {
    return instance.post('/people/changepassword', data, { headers: headers() });
}
export function updateprofile(data) {
    return instance.post('/people/changeprofile', data, { headers: { 'Content-Type': 'multipart/form-data', 'x-access-token': token() } });
}
export function getdocumentbrowser(data) {
    return instance.post(`/document/getdocumentbrowser/`, data, { headers: headers() });
}
export function getdocumentFile(doclink) {
    return instance.get(`/document/getfile/`, { 
        headers: headers(), 
        responseType: "blob",
        params: {
            doclink: doclink
        }
    });
}
export function deletedocument(data) {
    return instance.post(`/document/deletedocument/`, data, { headers: headers() });
}
export function getrequestdetail(data) {
    return instance.post('/request/getrequestdetail', data, { headers: headers() });
}
export function updaterequest(data) {
    return instance.post('/request/updaterequest', data, { headers: headers() });
}
export function getdashboard() {
    return instance.get('/dashboard/getdashboard', { headers: headers() });
}
export function creategroup(data) {
    return instance.post('/message/group', data, { headers: headers() });
}
export function createmessage(data) {
    return instance.post('/message/message', data, { headers: headers() });
}
export function allmessage(data) {
    return instance.post('/message/allmessage', data, { headers: headers() });
}

export function forgotpasswords(data) {
    return instance.post('/user/forgotpassword', data);
}
export function passwordreset(data) {
    return instance.post('/user/password-reset', data);
}
export function getLogs(query) {
    return instance.get(`/log/getLogs`, { 
        headers: headers(),
        params: query
    });
}

export function getFolders(query) {
    return instance.get('/document/folder', { 
        headers: headers(),
        params: query
    });
}
export function addFolder(data) {
    return instance.post('/document/folder', data, { headers: headers() });
}
export function updateFolder(data, id) {
    return instance.put(`/document/folder/${id}`, data, { headers: headers() });
}
export function deleteFolder(id) {
    return instance.delete(`/document/folder/${id}`, { headers: headers() });
}

export function getSettingFolders() {
    return instance.get('/document/settingfolder', { headers: headers() });
}
export function lockFolder(data) {
    return instance.post('/document/lockfolder', data, { headers: headers() });
}

export function unlockFolder(data) {
    return instance.post('/document/unlockfolder', data, { headers: headers() });
}

export function getsetting() {
    return instance.get('/setting', { headers: headers() });
}
export function addsetting(data) {
    return instance.post(`/setting`, data, { headers: headers() });
}
export function updatesetting(data, id) {
    return instance.put(`/setting/${id}`, data, { headers: headers() });
}

// app
export function getAvailableModules() {
    return instance.get(`/license/apps`, { headers: headers() });
}

export const verifyToken = async (data) => {
    return instance.post(`/user/verifyToken`, data, { headers: headers() });
}
// xero
export function getbalancesheet() {
    return instance.get(`/accounting/balancesheet`, { headers: headers() });
}

export function addAdhocTask(data) {
    return instance.post(`/adhocTask`, data, { headers: { 'Content-Type': 'multipart/form-data', 'x-access-token': token() } })
}

export function updateAdhocTask(data, id) {
    return instance.put(`/adhocTask/${id}`, data, { headers: headers() })
}

export function resendAdhocTask(data, id) {
    return instance.put(`/adhocTask/resend/${id}`, data, { headers: headers() })
}

export function getAdhocTasks(params) {
    return instance.get(`/adhocTask`, { 
        headers: headers(),
        params: params ? params : {}
    });
}

export function getAdhocTask(id) {
    return instance.get(`/adhocTask/${id}`, { headers: headers() });
}

export function getAdhocTaskBySlug(slug_url) {
    return instance.get(`/adhocTask/filter/slug`, { 
        headers: headers(),
        params: {
            slug_url: slug_url
        }
    });
}

export function deleteAdhocTask(id) {
    return instance.delete(`/adhocTask/${id}`, { headers: headers() });
}

export function getTemplateAdhoc() {
    return instance.get(`/adhocTask/template/base64`, { headers: headers() });
}

export function getRegisterUserGuideDoc() {
    return instance.get(`/adhocTask/userguide/base64`, { headers: headers() });
}

export function resignSigners(data) {
    return instance.post(`/newtask/signer/resign`, data, { headers: headers() })
}

// api for todo task
export function getTodoTask(id) {
    return instance.get(`/todoTask/${id}`, { headers: headers() });
}
export function getTodoTasks(params) {
    return instance.get('/todoTask', { 
        headers: headers(),
        params: params
    });
}
export function addTodoTask(data) {
    return instance.post('/todoTask', data, { headers: { 'Content-Type': 'multipart/form-data', 'x-access-token': token() } })
}
export function updateTodoTask(data, id) {
    return instance.put(`/todoTask/${id}`, data, { headers: { 'Content-Type': 'multipart/form-data', 'x-access-token': token() } })
}
export function deleteTodoTask(id) {
    return instance.delete(`/todoTask/${id}`, { headers: headers() });
}
