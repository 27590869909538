// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items/staff-menu-list';
import { getStorageUser } from "utils/common";

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = ({ apps }) => {
    const tokenuser = getStorageUser();
    const appAccounting = apps.find(x => x.code === "Accounting");

    const navItems = menuItem.items.map((item) => {
        if (item.id === "xero") {
            item.children[0].url = `${appAccounting?.link}/middlepage?token=${tokenuser?.token}&organizationId=${tokenuser?.organizationId}`;
        }

        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;
